const styles = {
  line: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContainer: {
    flexGrow: 1,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
};

export default styles;
