import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AccountBalance,
  CurrencyExchange,
  JoinFull,
} from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { encryptURL } from 'utils/functions';
import { RelatoriosContext } from 'contexts/RelatoriosContext';
import { LançamentosContext } from 'contexts/LançamentosContext';
import { useModal } from 'components/Modals';
import Container from 'components/Container';
import Loader from 'components/Loader';
import Card from 'components/Card';
import styles from './styles';
import moment from 'moment';
import TransferênciaModal from './Modals/TransferênciaModal';

const Contas = () => {
  const navigate = useNavigate();
  const { getURLRelatorio } = useContext(RelatoriosContext);
  const { getContas, contas, getLoading } = useContext(LançamentosContext);
  const { openModal } = useModal();

  useEffect(() => {
    getContas();
  }, []);

  const getExtrato = (item) =>
    getURLRelatorio({
      data: {
        codigo: 'EXT0000001',
        conta: item?.id,
        datai: moment().startOf('month').format('YYYY-MM-DD'),
        dataf: moment().endOf('month').format('YYYY-MM-DD'),
      },
    });

  const getSaldo = (saldo) => {
    if (Boolean(saldo) || saldo === 0) {
      return saldo?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }
    return '-';
  };

  const conciliar = (item) => {
    const hash = encryptURL({ id: item?.id });
    navigate(`/app/Lancamentos/Conciliar/${hash}`);
  };

  const Item = ({ item }) => (
    <Grid item xs={12} sm={6} lg={4} display="flex">
      <Card style={styles?.card}>
        <ButtonBase
          onClick={() => navigate('/app/Lancamentos', { state: item })}
        >
          <Box sx={styles?.cardContainer}>
            <Box sx={styles?.line}>
              <Typography variant="h6" textAlign="start">
                {item?.descricao}
              </Typography>
              <Typography variant="h6" color={item?.saldo < 0 && 'secondary'}>
                {getSaldo(item?.saldo)}
              </Typography>
            </Box>
            <Box sx={styles?.line}>
              <Typography variant="body2">Conciliado:</Typography>
              <Typography
                variant="body2"
                color={item?.saldo < 0 && 'secondary'}
              >
                {getSaldo(item?.saldo_conciliado)}
              </Typography>
            </Box>
            <Box sx={styles?.line}>
              <Typography variant="body2">Pendente:</Typography>
              <Typography
                variant="body2"
                color={item?.saldo < 0 && 'secondary'}
              >
                {getSaldo(item?.saldo_pendente)}
              </Typography>
            </Box>
          </Box>
        </ButtonBase>
        <Box sx={styles?.buttonContainer}>
          <Tooltip title="Extrato do mês corrente">
            <IconButton size="small" onClick={() => getExtrato(item)}>
              <AccountBalance fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Transferência">
            <IconButton
              size="small"
              onClick={() =>
                openModal(
                  <TransferênciaModal item={item} callback={getContas} />,
                  90
                )
              }
            >
              <CurrencyExchange fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Conciliar (OFX)">
            <IconButton size="small" onClick={() => conciliar(item)}>
              <JoinFull fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Card>
    </Grid>
  );

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Grid container spacing={2}>
        {contas?.map((item) => (
          <Item item={item} key={item?.id} />
        ))}
      </Grid>
    </Container>
  );
};

export default Contas;
