import { createContext, useState } from 'react';
import api from 'services/api';

export const DocumentosContext = createContext();

export const DocumentosProvider = ({ children }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [documentoItem, setDocumentoItem] = useState(null);
  const [postLoading, setPostLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [documento, setDocumento] = useState(null);

  const getDocumento = async (id) => {
    try {
      setGetLoading(true);
      setDocumento(null);
      const { data } = await api.get(`/Cadastros/Documento/${id}`, {
        params: {
          Itens: true,
          Registros: true,
          Entrada: true,
          Financeiro: true,
          Totais: true,
        },
      });
      setDocumento(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const postDocumento = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      const { data: res } = await api.post('/Cadastros/Documento', data);
      if (cb) {
        cb(res);
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const postDocumentos = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Multiple', data);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteDocumento = async ({ id, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Cadastros/Documento/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const postAnexo = async ({ data, cb }) => {
    try {
      setPostLoading(true);
      await api.post('/Cadastros/Documento/Registro/Anexo', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setPostLoading(false);
    }
  };

  const deleteAnexo = async ({ id, cb }) => {
    try {
      setDeleteLoading(true);
      await api.delete(`/Cadastros/Documento/Registro/Anexo/${id}`);
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  const getDocumentoItem = async (id) => {
    try {
      setGetLoading(true);
      setDocumentoItem(null);
      const { data } = await api.get(`/Cadastros/Documento/Item/${id}`, {
        params: {
          Estrutura: true,
          Processo: true,
          Imposto: true,
          Mc: true,
          Vinculo: true,
          Variacao: true,
          Movimentos: true,
        },
      });
      setDocumentoItem(data);
    } catch (error) {
    } finally {
      setGetLoading(false);
    }
  };

  const deleteDocumentoItemVinculo = async ({ params, cb = null }) => {
    try {
      console.log(params);
      setDeleteLoading(true);
      await api.delete(`/Cadastros/Documento/Item/Vinculo`, {
        params,
      });
      if (cb) {
        cb();
      }
    } catch (error) {
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <DocumentosContext.Provider
      value={{
        postLoading,
        postDocumentos,
        postDocumento,
        getDocumento,
        getLoading,
        documento,
        postAnexo,
        deleteAnexo,
        deleteLoading,
        deleteDocumento,
        getDocumentoItem,
        documentoItem,
        deleteDocumentoItemVinculo,
      }}
    >
      {children}
    </DocumentosContext.Provider>
  );
};
