import moment from 'moment';
import CryptoJS from 'crypto-js';
import api from 'services/api';
import LogoMercadoLivre from 'assets/mercado_livre.svg';
import LogoMercadoLivreFull from 'assets/mercado_livre_full.svg';

export const findOnArray = (value, arr = [], field) => {
  const rec = arr.find((i) => i.value === value);
  if (field) {
    return rec?.[field] || null;
  }
  return rec || null;
};

export const summarizer = (arr = [], field) =>
  arr?.length &&
  Number(
    arr
      ?.map((i) => i[field])
      ?.reduce((prev, next) => prev + next)
      ?.toFixed(2)
  );

export const filterSearch = (data = [], value = '') =>
  data?.filter((f) => {
    return Object.keys(f).some(
      (key) =>
        (f[key] || '')
          ?.toString()
          ?.toLowerCase()
          ?.indexOf(value?.toLowerCase()) !== -1
    );
  });

export const checkInfosProduto = async ({ produto_id, ...rest }) => {
  const { data } = await api.get(`/Cadastros/Produto/${produto_id}`, {
    params: { ...rest, Midias: true, Descricao: true },
  });
  const imagem = data?.ProdutoImagems?.find((f) => f?.Midium?.tipo === 'IMAGEM')
    ?.Midium?.url;
  const descricao = data?.ProdutoDescricaos?.find(
    (f) => f?.tipo === 'COMPLETA' || f?.tipo === 'REDUZIDA'
  )?.descricao;
  return { ...data, imagem, descricao };
};

export const getDatePagto = (values) => {
  let start_date = moment(values?.start_date).format('YYYY-MM-DD');
  if (values?.fora === 'SEMANA') {
    start_date = moment().add(1, 'week').format('YYYY-MM-DD');
  }
  if (values?.fora === 'QUINZENA') {
    start_date = moment().add(15, 'days').format('YYYY-MM-DD');
  }
  if (values?.fora === 'MES') {
    start_date = moment().add(1, 'month').format('YYYY-MM-DD');
  }

  if (Boolean(values?.intervalop >= 0)) {
    start_date = moment(start_date)
      .add(values?.intervalop, 'days')
      .format('YYYY-MM-DD');
    if (values?.parcela !== 1) {
      start_date = moment(start_date)
        .add(values?.intervalo * (values?.parcela - 1), 'days')
        .format('YYYY-MM-DD');
    }
  } else {
    start_date = moment(start_date)
      .add(values?.intervalo * values?.parcela, 'days')
      .format('YYYY-MM-DD');
  }

  if (values?.fixar && values?.intervalo % 30 === 0) {
    const diaFixo = moment(values?.start_date).format('DD');
    start_date = moment(start_date).set('D', diaFixo).format('YYYY-MM-DD');
  }

  if (Boolean(values?.dia)) {
    while (Number(moment(start_date).format('DD')) !== values?.dia) {
      start_date = moment(start_date).add(1, 'day').format('YYYY-MM-DD');
    }
  }
  return start_date;
};

export const validarCPF = (cpf = '') => {
  cpf = cpf?.replace(/[^\d]+/g, '');
  if (cpf == '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == '00000000000' ||
    cpf == '11111111111' ||
    cpf == '22222222222' ||
    cpf == '33333333333' ||
    cpf == '44444444444' ||
    cpf == '55555555555' ||
    cpf == '66666666666' ||
    cpf == '77777777777' ||
    cpf == '88888888888' ||
    cpf == '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;
  return true;
};

export const validarCNPJ = (cnpj = '') => {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
};

export const renderLogo = (value) => {
  const style = { objectFit: 'contain', width: '100%', height: '100%' };
  switch (value) {
    case 'MERCADO LIVRE':
      return <img src={LogoMercadoLivre} style={style} />;
    case 'MERCADO LIVRE fullfilment':
      return <img src={LogoMercadoLivreFull} style={style} />;
    default:
      return value;
  }
};

export const encryptURL = (word, key = 'E-Solution') => {
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
};

export const decryptURL = (word, key = 'E-Solution') => {
  let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  try {
    const a = JSON.parse(bytes);
    return a;
  } catch (error) {
    return null;
  }
};
